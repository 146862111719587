<template>
  <div class="header-container">
    <a
      href="https://twitter.com/pandadotnetwork"
      target="_blank"
      class="underliner"
      >Twitter</a
    >
    <span>⋆</span>
    <a
      href="https://chrome.google.com/webstore/detail/kdkgijnijnbincicbnopccopncfedgba"
      target="_blank"
      class="underliner"
      >Subscribe</a
    >
    <span>⋆</span>
    <a href="mailto:advertise@panda.network" target="_blank" class="underliner"
      >Advertise</a
    >
  </div>
  <!-- /.header-container -->
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.header-container {
  display: block;
  float: right;
  color: rgba($color: #000000, $alpha: 0.3);
  margin-top: 35px;
  //   animation-delay: 6s !important;
  a,
  span {
    float: right;
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    transition: color 0.35s;
  }
  a:nth-child(1) {
    &:before {
      background-color: #843ed5;
    }
    &:hover {
      color: #843ed5;
    }
  }
  a:nth-child(3) {
    &:before {
      background-color: #0772ff;
    }
    &:hover {
      color: #0772ff;
    }
  }
  a:nth-child(5) {
    &:before {
      background-color: #66b303;
    }
    &:hover {
      color: #66b303;
    }
  }
  .underliner {
    position: relative;
  }

  .underliner::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
  &.header-bottom {
    color: #000;
    float: left;
  }

  @media (hover: hover) and (pointer: fine) {
    .underliner:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
}
@media (max-width: $mobile-max) {
  .header-container {
    display: block;
  }
}
</style>
