<template>
  <div class="links-container" ref="links" :class="{ isMobile: app.isMobile }">
    <div
      class="link animate__animated animate__fadeIn"
      v-for="link in data.items"
      :key="link.order"
    >
      <div
        class="link-inner"
        :data-image="require(`@/assets/links/${link.url}.png`)"
        :data-order="link.order"
      >
        <a
          :href="'https://' + link.url"
          target="_blank"
          class="underline"
          :style="{ 'text-decoration-color': colors[link.order] }"
          :underline-color="colors[link.order]"
        >
          {{ link.name + "&nbsp;" }}
        </a>
      </div>
    </div>
  </div>
  <!-- /.links-container -->
</template>

<script>
const colors = [
  "#FFC312", //sari
  "#EE5A24", // koyu turuncu
  "#0652DD", //mavi
  "#A3CB38", //açık yeşil
  "#D980FA", //mor
  "#F79F1F", //turuncu
  "#EA2027", //kirmizi
  "#C4E538", //apaçık yeşil
  "#4834d4", //mor aussie
  "#6ab04c", //yeşil aussie
];

const data = require(`@/inc/links.json`);

import LinkItem from "@/inc/linkHover.js";

export default {
  name: "Links",
  components: {},
  data() {
    return {
      colors,
      data,
    };
  },
  store: ["app"],
  mounted() {
    if (!this.app.isMobile) {
      dispatchEvent(new Event("load"));
      this.$refs.links.querySelectorAll(".link-inner").forEach((element) => {
        new LinkItem(element);
      });
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.links-container {
  display: block;
  line-height: 62px;
  width: 100%;
  max-width: 1440px;
  padding-bottom: 100px;
  float: left;
  .link {
    display: inline-block;
    width: 50%;
    float: left;
    height: 66px;
    line-height: 72px;
    margin-top: 6px;
    position: relative;
    .link-inner {
      display: inline-block;
    }
    @for $i from 1 through 21 {
      &:nth-child(#{$i}) {
        animation-delay: ($i * 500ms + 1800ms);
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .links-container.isMobile {
    display: block;
    margin-top: 40px;
    .link {
      width: 100%;
      height: auto;
      line-height: 150%;
      a {
        font-size: 24px;
        text-decoration: underline;
        text-decoration-style: wavy;
        // text-decoration-style: dotted;
        text-underline-offset: 5px;
        display: block;
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .links-container {
    display: block;
    margin-top: 40px;
    .link {
      width: 100%;
      height: auto;
      //   line-height: 100%;
    }
  }
}
</style>
