<template>
  <div id="app">
    <video
      class="preload-video"
      preload=""
      loop
      src="@/assets/colors.mp4"
    ></video>
    <transition name="fade">
      <Mood v-if="app.state == 'mood'" />
    </transition>
    <transition name="fade">
      <Circles class="circles" v-if="app.state !== 'intro'" />
    </transition>
    <transition name="fade">
      <Intro v-if="app.state == 'intro'" />
      <div class="after-intro" v-else-if="app.state == 'main'">
        <transition name="fade">
          <Confetti v-if="showConfetti" />
        </transition>
        <div class="content">
          <Header class="animate__animated animate__fadeIn header-top" />
          <div class="logo" @click="onLogoClick()">
            <div
              class="untuk-cursor"
              @mouseenter="onVideoMouseEnter"
              @mouseleave="onVideoMouseLeave"
            >
              <video
                autoplay
                loop
                src="@/assets/colors.mp4"
                ref="logoVideo"
                :class="{ videoMouseEnter }"
              ></video>
            </div>
            <!-- /.untuk-cursor -->
          </div>
          <Headline />
          <Links class="animate__animated animate__fadeIn animate__delay-2s" />
          <Header class="animate__animated animate__fadeIn header-bottom" />
        </div>
        <!-- /.content -->
      </div>
      <!-- /.after-intro -->
    </transition>
  </div>
</template>

<script>
import Headline from "@/components/Headline.vue";
import Circles from "@/components/Circles.vue";
import Confetti from "@/components/Confetti.vue";
import Links from "@/components/Links.vue";
import Intro from "@/components/Intro.vue";
import Mood from "@/components/Mood.vue";
import Header from "@/components/Header.vue";
import "animate.css";

export default {
  name: "App",
  components: {
    Headline,
    Circles,
    Confetti,
    Links,
    Intro,
    Mood,
    Header,
  },
  data() {
    return {
      showConfetti: false,
      videoMouseEnter: false,
    };
  },
  store: ["app"],
  beforeMount() {
    if (this.app.isMobile) {
      this.app.state = "main";
      this.app.circlesOpacity = 0.3;
    }
  },
  mounted() {
    const linkElement = document.querySelector("link[rel=icon]");
    let counter = 0;
    setInterval(() => {
      linkElement.href = `/favicon/${(counter++ % 7) + 1}.png`;
    }, 3000);
  },
  methods: {
    onVideoMouseEnter() {
      this.videoMouseEnter = true;
      document.querySelector("video").playbackRate = 10.8;
      this.app.circlesOpacity = 1;
      if (this.app.isMobile) {
        setTimeout(() => {
          this.onVideoMouseLeave();
        }, 3000);
      }
    },
    onVideoMouseLeave() {
      this.videoMouseEnter = false;
      document.querySelector("video").playbackRate = 0.8;
      this.app.circlesOpacity = 0.3;
    },
    onLogoClick() {
      this.showConfetti = !this.showConfetti;
      setTimeout(() => {
        this.showConfetti = false;
      }, 3500);
    },
  },
};
</script>

<style lang="scss">
@import "~@/inc/reset.scss";
@import "~@/inc/common.scss";
@import "~@/inc/once.scss";

#app {
  font-family: "Metropolis";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-weight: 500;
  font-size: 32px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
}

.preload-video {
  visibility: hidden;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100px;
}

.circles {
  transition: opacity 500ms;
}
.content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 30px;
  box-sizing: border-box;
}
.logo {
  margin-top: 20px;
  margin-left: -8px;
  //   background: red;
  transform: scale(1);
  transition: all 0.2s;
  overflow: hidden;
  padding: 4px;
  backface-visibility: hidden;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: clamp(124px, 45vh, 200px);
  .untuk-cursor {
    width: calc(100% - -10px);
    overflow: hidden;
    padding: 10px;
    margin-left: -10px;
    &:hover {
      animation: cursor 0.9s linear infinite;
    }
  }
  &:active {
    transform: scale(0.85);
  }
  video {
    object-fit: cover;
    backface-visibility: hidden;
    mask-image: url("~@/assets/logo.svg");
    mask-repeat: no-repeat;
    clip: auto;
    width: 100%;
    mask-size: clamp(104px, 10vw, 200px);
    height: clamp(104px, 10vw, 200px);
    overflow: hidden;
    &.videoMouseEnter {
      animation: rumble 0.3s linear infinite;
    }
  }
}

@keyframes cursor {
  0%,
  100% {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>❤️</text></svg>")
        16 0,
      auto;
  }
  25% {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🐼</text></svg>")
        16 0,
      auto;
  }
  75% {
  }
}

@keyframes rumble {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.97);
  }
  75% {
    transform: scale(1.03);
  }
}
#app .header-bottom {
  display: none;
}
@media (max-width: $mobile-max) {
  #app .header-top {
    display: none;
  }
  #app .header-bottom {
    display: block;
    padding-bottom: 30px;
  }
}
</style>
