<template>
  <div class="mood-container">
    <transition name="fade">
      <div class="fade-container" v-if="!nextPageClicked">
        <img class="header" src="@/assets/choose-your-mood.svg" alt="" />
        <div class="moods">
          <div class="mood">
            <a href="javascript:;" class="mood-silent" @click="nextPage(false)">
              <img class="static" src="@/assets/panda.jpg" alt="" />
              <img src="@/assets/panda.gif" alt="" />
              <span class="text">SOUND OFF</span>
              <span class="emo">😴</span>
            </a>
          </div>
          <div class="mood">
            <a href="javascript:;" class="mood-loud" @click="nextPage(true)">
              <img class="static" src="@/assets/push.jpg" alt="" />
              <img src="@/assets/push.gif" alt="" />
              <span class="text">MUSIC ON</span>
              <span class="emo">🤘</span>
            </a>
          </div>
        </div>
        <!-- /.moods -->
      </div>
    </transition>
  </div>
  <!-- /.mood-container -->
</template>

<script>
import VanillaTilt from "vanilla-tilt";
export default {
  name: "Mood",
  components: {},
  data() {
    return {
      nextPageClicked: false,
    };
  },
  store: ["app"],
  mounted() {
    VanillaTilt.init(document.querySelectorAll(".moods a"), {
      reverse: true, // reverse the tilt direction
      max: 15, // max tilt rotation (degrees)
      startX: 0, // the starting tilt on the X axis, in degrees.
      startY: 0, // the starting tilt on the Y axis, in degrees.
      perspective: 800, // Transform perspective, the lower the more extreme the tilt gets.
      scale: 1.3, // 2 = 200%, 1.5 = 150%, etc..
      speed: 100, // Speed of the enter/exit transition
      transition: true, // Set a transition on enter/exit.
      axis: null, // What axis should be disabled. Can be X or Y.
      reset: true, // If the tilt effect has to be reset on exit.
      glare: true, // if it should have a "glare" effect
      "max-glare": 0.7, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
      "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise
      // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
      "mouse-event-element": null, // css-selector or link to HTML-element what will be listen mouse events
      // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
      gyroscope: false, // Boolean to enable/disable device orientation detection,
      gyroscopeMinAngleX: -45, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
      gyroscopeMaxAngleX: 45, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
      gyroscopeMinAngleY: -45, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
      gyroscopeMaxAngleY: 45, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
    });
  },
  methods: {
    nextPage(shouldPlay) {
      if (shouldPlay) document.querySelector("audio").play();
      this.nextPageClicked = true;
      setTimeout(() => {
        this.app.circlesOpacity = 0;
      }, 1500);
      setTimeout(() => {
        this.app.state = "intro";
        this.app.circlesOpacity = 0.3;
      }, 1500);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.mood-container {
  .fade-container {
    z-index: 100;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &::before,
  &::after {
    content: "";
    position: fixed;
    z-index: 1000;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: all 0.5s;
    transition-delay: 1s;
  }
  .header {
    font-size: 60px;
    display: inline-block;
    text-align: center;
    clear: both;
    margin-bottom: 30px;
  }
  .moods {
    width: 100%;
    justify-content: center;
    display: flex;
    .mood {
      width: 356px;
      height: 200px;
      margin: 40px 0px;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3);
      border-radius: 12px;
      @include click();
      &:first-child {
        margin-right: 160px;
      }
    }
    a {
      display: block;
      position: relative;
      width: 356px;
      height: 200px;
      transform: perspective(1000px);
      transform-style: preserve-3d;
      &:hover {
        cursor: cell;
      }
      &:hover img.static {
        opacity: 0;
      }
      &:hover .emo {
        opacity: 1;
      }
      .emo {
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 5;
        left: 50%;
        top: 50%;
        margin-left: -50px;
        margin-top: -50px;
        transform: translateZ(130px);
        font-size: 96px;
        opacity: 0;
        transition: all 0.2s;
      }
      .text {
        z-index: 4;
        position: absolute;
        width: 240px;
        left: (356px - 260px) / 2;
        display: inline-block;
        text-align: center;
        bottom: -20px;
        background: #fff;
        padding: 10px;
        border-radius: 12px;
        transform: translateZ(20px);
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
          0 18px 36px -18px rgba(0, 0, 0, 0.3);
      }
      img {
        border-radius: 12px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 0;
        &.static {
          z-index: 1;
        }
      }
    }
  }
}
@media (max-width: $mobile-max) {
  .mood-container {
    display: block;
  }
}
</style>
