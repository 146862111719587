<template>
  <div class="confetti-container"></div>
  <!-- /.confetti-container -->
</template>

<script>
export default {
  name: "Confetti",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    const Confettiful = function (el) {
      this.el = el;
      this.containerEl = null;

      this.confettiFrequency = 1;
      this.confettiAnimations = ["slow", "medium", "fast"];

      this._setupElements();
      this._renderConfetti();
    };

    Confettiful.prototype._setupElements = function () {
      const containerEl = document.createElement("div");
      const elPosition = this.el.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        this.el.style.position = "relative";
      }

      containerEl.classList.add("confetti-content");

      this.el.appendChild(containerEl);

      this.containerEl = containerEl;
    };

    Confettiful.prototype._renderConfetti = function () {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        confettiEl.innerHTML = "❤️";
        const confettiSize = Math.floor(Math.random() * 3) + 7 + "px";
        const confettiLeft =
          Math.floor(Math.random() * this.el.offsetWidth) + "px";
        const confettiAnimation =
          this.confettiAnimations[
            Math.floor(Math.random() * this.confettiAnimations.length)
          ];

        confettiEl.classList.add(
          "confetti",
          "confetti--animation-" + confettiAnimation
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;

        confettiEl.removeTimeout = setTimeout(function () {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        this.containerEl.appendChild(confettiEl);
      }, 50);
    };

    window.confettiful = new Confettiful(
      document.querySelector(".confetti-container")
    );
  },
};
</script>
<style lang="scss">
@import "~@/inc/common.scss";
.confetti-container {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  z-index: 1;
  @keyframes confetti-slow {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
    }
  }

  @keyframes confetti-medium {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
    }
  }

  @keyframes confetti-fast {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
    }
  }

  .confetti-content {
    perspective: 700px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0%;
    $animation-factor: 1;
    &--animation-slow {
      animation: confetti-slow 2.25s * $animation-factor linear 1 forwards;
    }

    &--animation-medium {
      animation: confetti-medium 1.75s * $animation-factor linear 1 forwards;
    }

    &--animation-fast {
      animation: confetti-fast 1.25s * $animation-factor linear 1 forwards;
    }
  }
}
@media (max-width: $mobile-max) {
  .confetti-container {
    display: block;
  }
}
</style>
