<template>
  <div class="headline-container">
    <h1 class="cd-headline letters rotate-2">
      <p ref="reveal" class="tReveal">Panda Network builds apps</p>
      <p ref="reveal2" class="tReveal reveal2" :class="{ hideReveal2 }">
        that are
      </p>
      <p class="rotating animate__animated animate__fadeIn animate__delay-1s">
        <span class="word wisteria">useful.</span>
        <span class="word belize">wonderful.</span>
        <span class="word pomegranate">colorful.</span>
        <span class="word green">beautiful.</span>
        <span class="word midnight">joyful.</span>
        <span class="word blue">mindful.</span>
        <span class="word cheerful">cheerful.</span>
      </p>
    </h1>
    <br />
    <br />
  </div>
  <!-- /.headline-container -->
</template>

<script>
import { rotateLetters } from "@/inc/rotatingLetters.js";
import { TextReveal } from "@/inc/textReveal.js";

export default {
  name: "Headline",
  components: {},
  data() {
    return {
      hideReveal2: true,
    };
  },
  mounted() {
    rotateLetters();
    let textReveal = new TextReveal(this.$refs.reveal);
    textReveal.in();
    let textReveal2 = new TextReveal(this.$refs.reveal2);
    setTimeout(() => {
      this.hideReveal2 = false;
      textReveal2.in();
    }, 150);
  },
  methods: {},
};
</script>
<style lang="scss">
@import "~@/inc/common.scss";
.headline-container {
  display: block;
  width: 100%;
  float: left;
  line-height: 62px;
  font-size: 64px;
  font-size: clamp(44px, 4vw, 84px);
  color: #000;
  margin-bottom: 50px;
  $text-shadow-color: #fff;
  text-shadow: -2px -2px $text-shadow-color, -2px -1.5px $text-shadow-color,
    -2px -1px $text-shadow-color, -2px -0.5px $text-shadow-color,
    -2px 0px $text-shadow-color, -2px 0.5px $text-shadow-color,
    -2px 1px $text-shadow-color, -2px 1.5px $text-shadow-color,
    -2px 2px $text-shadow-color, -1.5px 2px $text-shadow-color,
    -1px 2px $text-shadow-color, -0.5px 2px $text-shadow-color,
    0px 2px $text-shadow-color, 0.5px 2px $text-shadow-color,
    1px 2px $text-shadow-color, 1.5px 2px $text-shadow-color,
    2px 2px $text-shadow-color, 2px 1.5px $text-shadow-color,
    2px 1px $text-shadow-color, 2px 0.5px $text-shadow-color,
    2px 0px $text-shadow-color, 2px -0.5px $text-shadow-color,
    2px -1px $text-shadow-color, 2px -1.5px $text-shadow-color,
    2px -2px $text-shadow-color, 1.5px -2px $text-shadow-color,
    1px -2px $text-shadow-color, 0.5px -2px $text-shadow-color,
    0px -2px $text-shadow-color, -0.5px -2px $text-shadow-color,
    -1px -2px $text-shadow-color, -1.5px -2px $text-shadow-color;
  h1 {
    width: 100%;
    display: block;
    float: left;
    overflow: hidden;
    .reveal-outer {
      display: inline-block;
    }
    .tReveal {
      display: inline-block;
      transform-origin: 0% 50%;
      will-change: transform;
    }
  }
  p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-right: 12px;
    line-height: 150%;
    float: left;
    &.tReveal {
      float: left;
      clear: both;
      &.reveal2 {
        &.hideReveal2 {
          opacity: 0;
        }
      }
    }
    &.rotating {
      min-width: 200px;
      background: red !important;
    }
  }
  .word {
    position: absolute;
    // width: 340px;
    opacity: 0;
  }

  .letter {
    display: inline-block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
  }

  .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .letter.behind {
    transform: rotateX(-90deg);
  }

  .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .wisteria {
    color: #eb4d4b;
  }

  .belize {
    color: #f9ca24;
  }

  .pomegranate {
    color: #6ab04c;
  }

  .green {
    color: #4834d4;
  }

  .midnight {
    color: #be2edd;
  }

  .blue {
    color: #0652dd;
  }
  .cheerful {
    color: #fa8231;
  }
}
@media (max-width: $mobile-max) {
  .headline-container {
    display: block;
    font-size: clamp(20px, 100vw, 34px);
  }
}
</style>
