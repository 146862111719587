<template>
  <canvas
    class="canvas-container"
    id="canvas"
    :style="{ opacity: app.circlesOpacity }"
  />
  <!-- /.canvas-container -->
</template>

<script>
import { run } from "@/inc/circles.js";
export default {
  name: "Canvas",
  components: {},
  data() {
    return {};
  },
  store: ["app"],
  mounted() {
    run();
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.canvas-container {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: #fff;
}
@media (max-width: $mobile-max) {
  .canvas-container {
    display: block;
  }
}
</style>
