export function run() {
  let canvas = document.getElementById("canvas");
  const c = canvas.getContext("2d");
  c.scale(2, 2);

  let mouseX;
  let mouseY;

  canvas.height = window.innerHeight * 2;
  canvas.width = window.innerWidth * 2;

  let canvasWidth = canvas.width;
  let canvasHeight = canvas.height;

  const maxRadius = 32;

  document.onmousemove = function (e) {
    mouseX = e.clientX * 2;
    mouseY = e.clientY * 2;
  };

  window.addEventListener("resize", function () {
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    canvasWidth = canvas.width;
    canvasHeight = canvas.height;
  });

  function Circle(xCoordinate, yCoordinate, radius) {
    const randomColorNumber = Math.floor(Math.random() * colorArray.length);
    const randomTrueOrFalse = Math.floor(Math.random() * 2);

    this.xCoordinate = xCoordinate;
    this.yCoordinate = yCoordinate;
    this.radius = radius;
    this.color = colorArray[randomColorNumber];

    if (randomTrueOrFalse == 1) {
      this.xVelocity = -Math.random() * 1;
    } else {
      this.xVelocity = Math.random() * 1;
    }

    if (randomTrueOrFalse == 1) {
      this.yVelocity = -Math.random() * 1;
    } else {
      this.yVelocity = Math.random() * 1;
    }

    this.update = function () {
      this.xCoordinate += this.xVelocity;
      const xDistance = mouseX - this.xCoordinate;
      const yDistance = mouseY - this.yCoordinate;
      const originalRadius = radius;
      this.yCoordinate += this.yVelocity;

      if (
        this.xCoordinate + this.radius > canvasWidth ||
        this.xCoordinate - this.radius < 0
      ) {
        this.xVelocity = -this.xVelocity;
      }
      if (
        this.yCoordinate + this.radius > canvasHeight ||
        this.yCoordinate - this.radius < 0
      ) {
        this.yVelocity = -this.yVelocity;
      }

      if (
        xDistance < 200 &&
        xDistance > -200 &&
        this.radius < maxRadius &&
        yDistance < 200 &&
        yDistance > -200
      ) {
        this.radius += 1;
      } else if (
        (xDistance >= 200 && originalRadius < this.radius) ||
        (xDistance <= -200 && originalRadius < this.radius) ||
        (yDistance >= 200 && originalRadius < this.radius) ||
        (yDistance <= -200 && originalRadius < this.radius)
      ) {
        this.radius -= 1;
      }

      this.draw();
    };

    this.draw = function () {
      c.beginPath();
      c.arc(
        this.xCoordinate,
        this.yCoordinate,
        Math.abs(this.radius),
        0,
        Math.PI * 2
      );
      c.fillStyle = this.color;
      c.fill();
    };
  }

  const colorArray = [
    "#FFC312", //sari
    "#f9ca24", //sari2
    "#F79F1F", //turuncu
    "#EE5A24", // koyu turuncu
    "#EA2027", //kirmizi
    "#C4E538", //apaçık yeşil
    "#A3CB38", //açık yeşil
    // "#009432", //yeşil
    // "#006266", //koyu yeşil
    "#0652DD", //mavi
    "#D980FA", //mor
    "#4834d4", //mor aussie
    "#6ab04c", //yeşil aussie
  ];

  const myCircle = new Circle(30, 80, 10);
  let circleArray = [];
  let circleCount = 50;
  if (window.innerWidth > 1280) {
    circleCount = (window.innerWidth / 1280) * 50 * 1.2;
  }

  for (let i = 0; i < circleCount; i++) {
    const randomXCoordinate = Math.random() * canvasWidth;
    const randomYCoordinate = Math.random() * canvasHeight;
    const randomRadius = Math.floor(Math.random() * 10) + 3;
    circleArray.push(
      new Circle(randomXCoordinate, randomYCoordinate, randomRadius)
    );
  }

  function updateAll() {
    c.clearRect(0, 0, canvasWidth, canvasHeight);
    myCircle.update();
    for (let i = 0; i < circleArray.length; i++) {
      circleArray[i].update();
    }
    window.requestAnimationFrame(updateAll);
  }

  updateAll();
}
