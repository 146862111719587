import Vue from "vue";
import App from "./App.vue";
import isMobile from "@/inc/isMobile.js";

Vue.config.productionTip = false;

import VueStash from "vue-stash";
Vue.use(VueStash);

new Vue({
  render: (h) => h(App),
  data: {
    store: {
      app: {
        isMobile,
        state: "intro",
        circlesOpacity: 1,
      },
    },
  },
}).$mount("#app");
