<template>
  <div class="intro-container" :class="{ loading }">
    <svg class="circles" width="100%" height="100%" viewBox="0 0 1400 1400">
      <def>
        <path
          id="circle-1"
          d="M250,700.5A450.5,450.5 0 1 11151,700.5A450.5,450.5 0 1 1250,700.5"
        />
        <path
          id="circle-2"
          d="M382,700.5A318.5,318.5 0 1 11019,700.5A318.5,318.5 0 1 1382,700.5"
        />
        <path
          id="circle-3"
          d="M487,700.5A213.5,213.5 0 1 1914,700.5A213.5,213.5 0 1 1487,700.5"
        />
        <path
          id="circle-4"
          d="M567.5,700.5A133,133 0 1 1833.5,700.5A133,133 0 1 1567.5,700.5"
        />
      </def>
      <text class="circles__text circles__text--1">
        <textPath
          class="circles__text-path"
          xlink:href="#circle-1"
          aria-label=""
          textLength="2830"
        >
          Productive ⋆ Efficient ⋆&nbsp;
        </textPath>
      </text>
      <text class="circles__text circles__text--2">
        <textPath
          class="circles__text-path"
          xlink:href="#circle-2"
          aria-label=""
          textLength="2001"
        >
          Love ⋆ Passion ⋆ Courage ⋆&nbsp;
        </textPath>
      </text>
      <text class="circles__text circles__text--3">
        <textPath
          class="circles__text-path"
          xlink:href="#circle-3"
          aria-label=""
          textLength="1341"
        >
          Honesty ⋆ Sharing ⋆&nbsp;
        </textPath>
      </text>
      <text class="circles__text circles__text--4">
        <textPath
          class="circles__text-path"
          xlink:href="#circle-4"
          aria-label=""
          textLength="836"
        >
          We ⋆ are ⋆ you ⋆ are ⋆&nbsp;
        </textPath>
      </text>
    </svg>
    <button class="enter">
      <div class="enter__bg"></div>
      <span class="enter__text">ENTER</span>
    </button>
  </div>
  <!-- /.intro-container -->
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "Intro",
  components: {},
  data() {
    return {
      startTL: null,
      enterCtrl: null,
      circleText: null,
      circleTextTotal: null,
      loading: true,
    };
  },
  store: ["app"],
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.loading = false;
        this.start();
      }
    };
  },
  methods: {
    start() {
      this.enterCtrl = document.querySelector(".enter");
      let enterBackground = document.querySelector(".enter__bg");
      this.circleText = [document.querySelectorAll("text.circles__text")];
      this.circleTextTotal = this.circleText.length;

      //SETUP
      // need to set the transform origin in the center
      gsap.set(this.circleText, { transformOrigin: "50% 50%" });
      // hide on start
      gsap.set([this.circleText], {
        opacity: 0,
      });
      // don't allow to hover
      gsap.set(this.enterCtrl, { pointerEvents: "none" });

      //INIT
      // click and hover events for the "enter" button:
      this.enterMouseEnterEv = () => {
        gsap.killTweensOf([enterBackground, this.circleText]);

        gsap.to(enterBackground, {
          duration: 0.8,
          ease: "power4",
          scale: 1.2,
          opacity: 1,
        });

        gsap.to(this.circleText, {
          duration: 4,
          ease: "power4",
          rotate: "+=180",
          stagger: {
            amount: -0.3,
          },
        });
      };
      this.enterMouseLeaveEv = () => {
        //gsap.killTweensOf(DOM.enterBackground);
        gsap.to(enterBackground, {
          duration: 0.8,
          ease: "power4",
          scale: 1,
        });
      };
      this.enterClickEv = () => this.enter();

      setTimeout(() => {
        this.enterCtrl.addEventListener("mouseenter", this.enterMouseEnterEv);
        this.enterCtrl.addEventListener("mouseleave", this.enterMouseLeaveEv);
        this.enterCtrl.addEventListener("click", this.enterClickEv);
      }, 1200);

      //START
      this.startTL = gsap
        .timeline()
        .addLabel("start", 0)
        // scale in the texts & enter button and fade them in
        .to(
          [this.circleText, this.enterCtrl],
          {
            duration: 2.5,
            ease: "expo",
            startAt: { opacity: 0, scale: 0.3 },
            scale: 1,
            opacity: 1,
            delay: 0,
            stagger: {
              amount: 1,
            },
          },
          "start"
        )
        // at start+1 allow the hover over the enter ctrl
        .add(
          () => gsap.set(this.enterCtrl, { pointerEvents: "auto" }),
          "start+=1"
        );
    },
    enter() {
      this.startTL.kill();
      this.enterCtrl.removeEventListener("mouseenter", this.enterMouseEnterEv);
      this.enterCtrl.removeEventListener("mouseleave", this.enterMouseLeaveEv);
      this.enterCtrl.removeEventListener("click", this.enterClickEv);
      gsap.set(this.enterCtrl, { pointerEvents: "none" });
      setTimeout(() => {
        this.app.circlesOpacity = 1;
      }, 750);
      setTimeout(() => {
        this.app.state = "main";
      }, 1500);
      setTimeout(() => {
        this.app.circlesOpacity = 0.3;
      }, 3000);
      gsap
        .timeline()
        .addLabel("start", 0)
        .to(
          this.enterCtrl,
          {
            duration: 1.5,
            ease: "expo.inOut",
            scale: 0.7,
            opacity: 0,
          },
          "start"
        )
        .to(
          this.circleText,
          {
            duration: 1.5,
            ease: "expo.inOut",
            scale: (i) => 1.5 + (this.circleTextTotal - i) * 0.3,
            opacity: 0,
            stagger: {
              amount: 0.2,
            },
          },
          "start"
        );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/inc/common.scss";
.intro-container {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  font-family: "Metropolis";
  transform: transale3d(0, 0, 0);
  &::after {
    background: url("~@/assets/loading.svg") #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 1;
    color: #000;
    transition: all 0.5s;
    -webkit-transform: translate3d(0, 0, 0);
    background-size: 55%;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 30px;
    box-sizing: border-box;
    z-index: 10000;
    content: " ";
    opacity: 0;
    pointer-events: none;
  }
  &.loading::before,
  &.loading::after {
    opacity: 1;
    pointer-events: auto;
  }
  video {
    opacity: 0.001;
    position: fixed;
    z-index: -10;
  }
  $button-width: 160px;
  .enter {
    display: block;
    border: 0;
    width: $button-width;
    height: $button-width;
    position: absolute;
    left: calc(50% - #{$button-width} / 2);
    top: calc(50% - #{$button-width} / 2);
    font: inherit;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    background: none;
    padding: 0;
    color: #843ed5;
    font-family: "Metropolis";
    opacity: 0;
    transition: transform 0.4s;
    cursor: pointer;
    font-size: 24px;
    &:active .enter__text {
      transform: scale(0.9) perspective(1px) !important;
    }
  }

  .enter__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border-radius: 50%;
    // border: 2px solid #4834d4;
    box-sizing: border-box;
    background: url("~@/assets/panda-stroke.svg");
    background-size: 100% 100%;
  }

  .enter__text {
    position: relative;
    display: inline-block;
    letter-spacing: 1px;
    transition: transform 0.2s;
    transform: scale(1) perspective(1px) !important;
  }

  .circles__text {
    text-transform: uppercase;
    transform-origin: 700px 700px;
    will-change: transform, opacity;
    font-weight: normal;
  }

  .circles {
    pointer-events: none;
    position: fixed;
    --dim: 186vmin;
    width: var(--dim);
    height: var(--dim);
    top: calc(50% - var(--dim) / 2);
    left: calc(50% - var(--dim) / 2);
  }

  .circles__text--1 {
    // font-size: 17vmin;
    font-size: clamp(120px, 17vmin, 180px);
    // font-family: var(--font-circle-1);
    // font-weight: var(--font-weight-circle-1);
    fill: #ffc312;
  }

  .circles__text--2 {
    // font-size: 13vmin;
    font-size: clamp(96px, 13vmin, 153px);
    // font-family: var(--font-circle-2);
    // font-weight: var(--font-weight-circle-2);
    fill: #ea2027;
  }

  .circles__text--3 {
    // font-size: 9vmin;
    font-size: clamp(70px, 9vmin, 120px);
    // font-family: var(--font-circle-3);
    // font-weight: var(--font-weight-circle-3);
    fill: #0652dd;
  }

  .circles__text--4 {
    // font-size: 5vmin;
    font-size: clamp(50px, 5vmin, 84px);
    // font-family: var(--font-circle-4);
    // font-weight: var(--font-weight-circle-4);
    fill: #a3cb38;
  }
}

@media (max-width: 1440px) {
  .intro-container {
    display: block;
    $button-width: 120px;
    .enter {
      width: $button-width;
      height: $button-width;
      left: calc(50% - #{$button-width} / 2);
      top: calc(50% - #{$button-width} / 2);
      font-size: 18px;
    }
  }
}
@media (max-width: 1280px) {
  .intro-container {
    display: block;
    $button-width: 110px;
    .enter {
      width: $button-width;
      height: $button-width;
      left: calc(50% - #{$button-width} / 2);
      top: calc(50% - #{$button-width} / 2);
      font-size: 16px;
    }
  }
}

@media (max-width: $mobile-max) {
  .intro-container {
    display: block;
  }
}
</style>
